import React, { useEffect, useState } from 'react'
import { deletewithheader, getWithheader } from '../../Api/Api'
import Sidebaar from '../../Component/Sidebar'
import TitleComp from '../../Component/TitleComp'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../Api/BaseUrl'

function ProductList() {
    const token = localStorage.getItem("paneltoken")
    let userid = localStorage.getItem("userid")
    const [data, setdata] = useState([])
    const [filter, setfilter] = useState("")
    const [store, setstore] = useState([])

    const navigate = useNavigate()
    const fetchdetail = async () => {
        let res = await getWithheader(`getuserproduct?id=${userid}`, token)
        setdata(res.data)
    }

    useEffect(() => {
        fetchdetail()
    }, [])

    const getRowId = (row) => row._id

    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>

                        <img src={`${BASE_URL}${params?.row?.image?.[0]?.img}`} style={{ width: "50px", height: "50px", borderRadius: "50%" }} alt="" />
                    </>
                )
            }

        },
        {
            field: "name",
            headerName: "Product Name",
            flex: 1,

        },
        {
            field: "storeName",
            headerName: "Store Name",
            flex: 2,
            renderCell: (params) => {
                return (
                    <>
                        <p>{params.row?.store?.title ?? params.row?.storeDetails?.title}</p>
                    </>
                )
            }

        },

        {
            field: "detail",
            headerName: "Short Detail",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "createdAt",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </>
                )
            }
        },

    ]


    const handledelete = async (e, deleid) => {
        e.preventDefault()
        const res = await deletewithheader(`product/${deleid}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            fetchdetail()
        } else {
            toast.error("Product Not Delete")
        }
    }

    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/product', { state: item })
    }

    const handlestore = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token)
        setstore(res.data)
    }

    useEffect(() => {
        handlestore()
    }, [])

    const handlefilter = async () => {
        let res = await getWithheader(`getproduct/${filter}`, token)
        setdata(res.data)
    }


    useEffect(() => {
        if (filter != "") {
            handlefilter()
        }
    }, [filter])


    return (
        <>
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Product List" />
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="d-flex gap-3 ">
                            <select name="" value={filter} onChange={(e) => setfilter(e.target.value)} className='form-control  form-select' id="" style={{ width: "max-content" }}>
                                <option value="">Select Store</option>
                                {store.map((item) => {
                                    return (
                                        <>
                                            <option value={item.url}>{item.title}</option>
                                        </>
                                    )
                                })}
                            </select>
                            <button className='btn bg-blue text-white' onClick={() => { fetchdetail(); setfilter("") }}>Clear All Filter</button>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <Box>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            />

                        </Box>
                    </div>
                </>
            } />
        </>
    )
}

export default ProductList
