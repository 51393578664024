import React, { useEffect, useState } from 'react'
import Loader from '../../Component/Loader'
import Sidebaar from '../../Component/Sidebar'

import TitleComp from '../../Component/TitleComp'
import { Form } from 'react-bootstrap'
import { getWithheader, postwithheader, putwithheader } from '../../Api/Api'
import { toast } from 'react-toastify'
import axios from 'axios'

function Openinghour() {
    const [loading, setloaing] = useState(false)
    const [monday, setmonday] = useState("")
    const [tuesday, settuesday] = useState("")
    const [wednesday, setwednesday] = useState("")
    const [thursday, setthursday] = useState("")
    const [editid, seteditid] = useState("")
    const [friday, setfriday] = useState("")
    const [saturday, setsaturday] = useState("")
    const [sunday, setsunday] = useState("")
    const [store, setstore] = useState("")
    const [storedata, setstoredata] = useState([])
    let userid = localStorage.getItem("userid")
    let token = localStorage.getItem("paneltoken")

    const handlesubmit = async (e) => {
        e.preventDefault();
        let requestdata = {
            store: store,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday
        }
        let res = ""
        if (editid) {
            res = await putwithheader(`openhour/${editid}`, token, requestdata)
        } else {
            res = await postwithheader('openhour', requestdata, token)
        }

        if (res.error == 0) {
            toast.success("Data Submitted Successfully");
            handleclear()

        } else {
            toast.error(res.message)
            setloaing(false)

        }

    }
    const handleget = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token)
        setstoredata(res.data)
    }

    useEffect(() => {
        handleget()
    }, [])

    useEffect(() => {
        if (store) {
            let filter = storedata.filter((item) => {
                return item._id == store
            })

            let url = filter?.[0].url
            console.log(url)

            handlegetstoreopen(url)

        }
    }, [store])

    const handlegetstoreopen = async (url) => {
        let res = await getWithheader(`openhour?storeUrl=${url}`, token)

        if (res.data.length > 0) {
            seteditid(res?.data?.[0]?._id)
            setmonday(res?.data?.[0]?.monday)
            settuesday(res?.data?.[0]?.tuesday)
            setwednesday(res?.data?.[0]?.wednesday)
            setthursday(res?.data?.[0]?.thursday)
            setfriday(res?.data?.[0]?.friday)
            setsaturday(res?.data?.[0]?.saturday)
            setsunday(res?.data?.[0]?.sunday)
        } else {
            handleclear()
        }
    }

    const handleclear = () => {
        setmonday("")
        seteditid("")
        settuesday("")
        setwednesday("")
        setthursday("")
        setfriday("")
        setsaturday("")
        setsunday("")
    }




    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <TitleComp title="Opening Hours" />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <p className='text-danger'>*You can write the time like this: 8am - 9pm, or if your store is close, then write closed.</p>

                                </div>
                            </div>
                            <Form onSubmit={handlesubmit}>
                                <div className="row gy-4">
                                    <div className="col-md-4">
                                        <label htmlFor="">Select Store</label>
                                        <select name=""
                                            className='form-control form-select'
                                            value={store}
                                            onChange={(e) => setstore(e.target.value)}
                                            id="">
                                            <option value="">--Select Store--</option>
                                            {storedata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.title}</option>

                                                    </>
                                                )
                                            })}

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="monday">Monday</label>
                                        <input type="text" id="monday"
                                            className='form-control'
                                            value={monday}
                                            onChange={(e) => setmonday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="tuesday">Tuesday</label>
                                        <input type="text" id="tuesday"
                                            className='form-control'
                                            value={tuesday}
                                            onChange={(e) => settuesday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="wednesday">Wednesday</label>
                                        <input type="text" id="wednesday"
                                            className='form-control'
                                            value={wednesday}
                                            onChange={(e) => setwednesday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="thursday">Thursday</label>
                                        <input type="text" id="thursday"
                                            className='form-control'
                                            value={thursday}
                                            onChange={(e) => setthursday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="friday">Friday</label>
                                        <input type="text" id="friday"
                                            className='form-control'
                                            value={friday}
                                            onChange={(e) => setfriday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="saturday">Saturday</label>
                                        <input type="text" id="saturday"
                                            className='form-control'
                                            value={saturday}
                                            onChange={(e) => setsaturday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="sunday">Sunday</label>
                                        <input type="text" id="sunday"
                                            className='form-control'
                                            value={sunday}
                                            onChange={(e) => setsunday(e.target.value)}
                                            placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-12">
                                        <button class="btn bgblue text-white px-5" type="submit">{editid ? "Update" : "Add"} Opening Hour</button>
                                    </div>
                                </div>
                            </Form>

                        </div>
                    </section>
                </>
            } />


        </>
    )
}

export default Openinghour