
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebaar from './Component/Sidebar';
import Dashboard from './Pages/Dashboard';
import AddLocation from './Pages/AddLocation';
import Magazine from './Pages/Magazine';
import PrivateRoutes from './Auth/PrivateRoutes';
import Login from './Auth/Login';
import Description from './Pages/Description/Description';
import DescriptionDetail from './Pages/Description/DescriptionDetail';
import AddProduct from './Pages/Product/AddProduct';
import ProductList from './Pages/Product/ProductList';
import AddOffer from './Pages/Offers/AddOffer';
import OfferList from './Pages/Offers/OfferList';
import AddEvent from './Pages/EventsPage/AddEvent';
import EventList from './Pages/EventsPage/EventList';
import CreateStore from './Pages/Store/CreateStore';
import Openinghour from './Pages/OpeningHour/Openinghour';
import "react-country-state-city/dist/react-country-state-city.css";
import ApplyCoupon from './Pages/Offers/ApplyCoupon';
import Profile from './Pages/Profile';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {/* <Route path='/dashboard' element={<Dashboard />} /> */}
          <Route path='/addlocation' element={<AddLocation />} />
          <Route path='/magzine' element={<Magazine />} />
          <Route path='/description' element={<Description />} />
          {/* <Route path='/descriptionlist' element={<DescriptionDetail />} /> */}
          <Route path='/product' element={<AddProduct />} />
          <Route path='/store' element={<CreateStore />} />
          <Route path='/productlist' element={<ProductList />} />
          <Route path='/addoffer' element={<AddOffer />} />
          <Route path='/offerlist' element={<OfferList />} />
          <Route path='/applycoupons' element={<ApplyCoupon />} />

          {/* <Route path='/event' element={<AddEvent />} />
          <Route path='/eventlist' element={<EventList />} /> */}
          <Route path='/openinghour' element={<Openinghour />} />
          <Route path='/profile' element={<Profile />} />


        </Route>
        <Route path='/' element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
