import axios from "axios";
import { BASE_URL } from "./BaseUrl";




export const postapiwithoutheader = async (endpoint, requestdata) => {
    try {
        const items = await axios.post(`${BASE_URL}${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const postApiWithHeader = async (endpoint, requestData, token) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await axios.post(`${BASE_URL}${endpoint}`, requestData, { headers });
        return response.data;
    } catch (error) {
        console.error(error);
        return { data: error };
    }
};


export const getwithoutheader = async (endpoint) => {
    try {
        const items = await axios.get(`${BASE_URL}${endpoint}`);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const deleteapi = async (endpoint) => {
    try {
        const items = await axios.delete(`${BASE_URL}${endpoint}`);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}


export const getWithheader = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.get(`${BASE_URL}${endpoint}`, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const postwithheader = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.post(`${BASE_URL}${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}


export const putwithheader = async (endpoint, token, requestdata) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.put(`${BASE_URL}${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const deletewithheader = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.delete(`${BASE_URL}${endpoint}`, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const deletewithid = async (endpoint, token, requestdata) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.delete(`${BASE_URL}${endpoint}`, requestdata, { headers },);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}