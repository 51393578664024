import React, { useEffect, useState } from 'react'
import Sidebaar from '../Component/Sidebar'
import TitleComp from '../Component/TitleComp'
import user from "../assets/images/user.png"
import { getWithheader } from '../Api/Api'
import { BASE_URL } from '../Api/BaseUrl'
import { Form, FormGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import {
    CitySelect,
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import axios from 'axios'

function Profile() {

    const [name, setname] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [gst, setgst] = useState("")
    const [editfile, seteditfile] = useState("")
    const [file, setfile] = useState("")


    const [pincode, setpincode] = useState("")


    const [map, setMap] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [latlong, setlatlong] = useState("")
    const [address, setAddress] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [fulladdress, setfulladdress] = useState("")

    const [profiledata, setprofiledata] = useState([])
    let token = localStorage.getItem("paneltoken")


    const handleprofile = async () => {
        let res = await getWithheader(`profile`, token)

        setprofiledata(res.data)
        setname(res.data.name)
        setemail(res.data.email)
        setphone(res.data.mobile)
        setpassword(res.data.password)
        setstate(res.data.state)
        setcity(res.data.city)
        setpincode(res.data.pincode)
        setfulladdress(res.data.fulladdress)
        setgst(res.data.gst)

        localStorage.setItem("profileimg", res.data.image)
    }

    useEffect(() => {
        handleprofile()
    }, [])

    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState("");

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                    setError("");
                },
                (err) => {
                    switch (err.code) {
                        case err.PERMISSION_DENIED:
                            setError("User denied the request for Geolocation.");
                            break;
                        case err.POSITION_UNAVAILABLE:
                            setError("Location information is unavailable.");
                            break;
                        case err.TIMEOUT:
                            setError("The request to get user location timed out.");
                            break;
                        default:
                            setError("An unknown error occurred.");
                    }
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };



    const handleclear = () => {
        // setname("")
        // setemail("")
        // setphone("")
        // setpassword("")
        // setcity("")
        // setpincode("")
        // setfulladdress("")
        // setgst("")

    }


    useEffect(() => {
        getLocation()
    }, [])


    const headers = {
        Authorization: `Bearer ${token}`
    }


    const handleupdate = async (e) => {
        e.preventDefault()
        let formdata = new FormData();
        formdata.append("_id", profiledata._id)
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("mobile", phone);
        formdata.append("state", state.name ?? state);
        formdata.append("password", password);
        formdata.append("city", city.name ?? city);
        formdata.append("pincode", pincode);
        formdata.append("fulladdress", fulladdress);
        formdata.append("gst", gst);
        formdata.append("type", "Vendor")
        formdata.append("lat", location.latitude)
        formdata.append("long", location.longitude)
        if (file) {
            formdata.append("image", file)
        }





        axios.put(`${BASE_URL}updateuser`, formdata, { headers }).then((res) => {
            console.log(res.data.error)
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handleclear()
            }
        }).catch((err) => {
            console.log(err.response.data)
            toast.error(err.response.data.message)
        })
    }


    // const handleimage = (e) => {
    //     e.preventDefault()
    //     let selectedfile = e.target.files[0]
    //     setfile(selectedfile)
    // }

    const handleimage = (e) => {
        e.preventDefault();
        let selectedfile = e.target.files[0];
        setfile(selectedfile)
        if (selectedfile) {
            const reader = new FileReader();
            reader.onload = () => {
                seteditfile(reader.result); // Set the preview URL of the image
            };
            reader.readAsDataURL(selectedfile);
        }
    };



    return (
        <>
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Profile" />
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="shadow bg-white text-center p-3">
                            <img src={`${BASE_URL}${profiledata.image}`} onError={(e) => e.target.src = user} style={{ height: "100px", objectFit: "cover" }} className="img-fluid rounded-circle" alt="" />
                            <h5 className='mt-4'>{profiledata.name}</h5>
                            <div>
                                <a href="" style={{ textDecoration: "none" }}>
                                    <i class="fa-solid fa-mobile text-dark me-3"></i>
                                    <span className='text-dark'>{profiledata.mobile}</span>
                                </a>
                            </div>
                            <div>
                                <a href="" className='mt-2' style={{ textDecoration: "none" }}>
                                    <i class="fa-solid fa-envelope text-dark me-3 "></i>
                                    <span className='text-dark'>{profiledata.email}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="bg-white shadow p-3" >
                            {/* <h3>Update Profile</h3> */}

                            <Form onSubmit={(e) => handleupdate(e)}>
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <FormGroup >
                                            <label htmlFor="checkingimg">
                                                <img
                                                    src={editfile || `${BASE_URL}${profiledata.image}`}
                                                    onError={(e) => (e.target.src = user)}
                                                    style={{ height: "100px", width: "100px", objectFit: "cover" }}
                                                    className="img-fluid rounded-circle"
                                                    alt="Profile Preview"
                                                />
                                            </label>
                                            <input id="checkingimg" type="file" placeholder='Name' className='form-control shadow-none d-none' onChange={handleimage} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Name' className='form-control shadow-none' value={name} onChange={(e) => setname(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Enter Mobile Number' className='form-control shadow-none' value={phone} onChange={(e) => setphone(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Email' className='form-control shadow-none' value={email} onChange={(e) => setemail(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Password' className='form-control shadow-none' value={password} onChange={(e) => setpassword(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='State' disabled className='form-control shadow-none' value={state} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <StateSelect
                                                countryid={101}

                                                onChange={(e) => setstate(e)}
                                                placeHolder="Change State"
                                            />

                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='State' disabled className='form-control shadow-none' value={city} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-3">
                                            <CitySelect
                                                countryid={101}
                                                stateid={state.id}
                                                onChange={(e) => {
                                                    setcity(e)
                                                }}
                                                placeHolder="Change City"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Pincode' className='form-control shadow-none' value={pincode} onChange={(e) => setpincode(e.target.value)} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-6">

                                        <FormGroup className='mt-3'>
                                            <input type="text" placeholder='Gst' className='form-control shadow-none' value={gst} onChange={(e) => setgst(e.target.value)} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-12">
                                        <FormGroup className='mt-3'>
                                            <textarea type="text" rows={5} placeholder='Address' className='form-control shadow-none' value={fulladdress} onChange={(e) => setfulladdress(e.target.value)} />
                                        </FormGroup>
                                    </div>

                                    <div className="">
                                        <button className='btn  bg-blue mt-3 text-white' >
                                            Update Profile
                                        </button>
                                    </div>
                                </div>
                            </Form>







                        </div>
                    </div>
                </>
            } />
        </>
    )
}

export default Profile
