import React, { useEffect, useState } from 'react'
import Sidebaar from '../../Component/Sidebar'
import { Form, FormGroup } from 'react-bootstrap'
import { getWithheader, postapiwithoutheader, postwithheader, putwithheader } from '../../Api/Api'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/BaseUrl'
import TitleComp from '../../Component/TitleComp'
import Loader from '../../Component/Loader'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

function AddProduct() {
    const { state } = useLocation()
    const [loading, setloading] = useState("")
    const token = localStorage.getItem("paneltoken")
    const [desc, setdesc] = useState("")
    let vendorid = localStorage.getItem("userid")
    const [editfile, seteditfile] = useState("")
    const [name, setname] = useState("")
    const [file, setfile] = useState("")
    const [selectedImages, setSelectedImages] = useState([])
    const [detail, setdetail] = useState("")
    const [storedata, setstoredata] = useState([])
    const navigate = useNavigate()
    const [store, setstore] = useState("")


    useEffect(() => {
        if (state) {
            setdetail(state.shortdetail)
            setdesc(state.detail)
            setname(state.name)
            setstore(state.store._id)
            seteditfile(state.image)
        }
    }, [state])


    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };



    const handlestore = async () => {
        let res = await getWithheader(`vendorstore/${vendorid}`, token)
        setstoredata(res.data)
    }

    useEffect(() => {
        handlestore()
    }, [])


    const handlefile = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setfile(selectedfile)
    }


    const handlesubmit = async (e) => {
        e.preventDefault();
        setloading(true)

        let formdata = new FormData();
        formdata.append("name", name)
        selectedImages.forEach((item, index) => {
            formdata.append(`image`, item);
        });
        formdata.append("store", store)
        formdata.append("shortdetail", detail)
        formdata.append("detail", desc)
        formdata.append("created_by", vendorid)

        let res = ""
        if (state) {
            res = await putwithheader(`update_product/${state._id}`, token, formdata)
        } else {
            res = await postwithheader('product', formdata, token)
        }

        console.log(res)
        if (res.error == "0") {
            setloading(false)
            toast.success(res.message)
            setTimeout(() => {
                navigate('/productlist')
            }, 500);
        } else {
            toast.error("Data not Submit")
            setloading(false)
        }
    }


    const handleImageChange = (e) => {
        const files = e.target.files;
        const imageArray = Array.from(files);
        setSelectedImages([...selectedImages, ...imageArray]);
    };

    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>

                    <section>
                        <div className="container">
                            <Form onSubmit={(e) => handlesubmit(e)}>
                                <div className="row gy-4">
                                    <div className="col-md-12">
                                        <div className="text-center">
                                            <TitleComp title={state ? "Edit Product" : "Add Product"} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <label htmlFor="">Name</label>
                                            <input type="text" value={name} onChange={(e) => setname(e.target.value)} className='form-control' />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Store</label>
                                        <select name="" value={store} onChange={(e) => setstore(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Store</option>
                                            {storedata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.title}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {/* {(file || editfile) && <div className="col-md-2">
                                        <div className="text-center">

                                            {file ? <img src={URL.createObjectURL(file)} className='img-fluid rounded-3' alt="" /> : <img src={`${BASE_URL}${editfile}`} className='img-fluid rounded-3' alt="" />}
                                        </div>
                                    </div>} */}

                                    <div className="col-md-4">
                                        <FormGroup>
                                            <label htmlFor="">Image</label>
                                            <input
                                                type="file"
                                                multiple
                                                // accept=".png, .jpg, .jpeg, .webp"
                                                onChange={handleImageChange}
                                                className="form-control"
                                                id="image-input"
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <label htmlFor="">Short Detail</label>
                                            <textarea name="" className='form-control' value={detail} onChange={(e) => setdetail(e.target.value)} id=""></textarea>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="">Product Detail</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={desc}
                                            onChange={handleEditorChange}
                                            className="custom-ckeditor"
                                        />
                                    </div>
                                    {(editfile) && <div className="col-md-2">
                                        <div className="text-center">

                                            <img src={`${BASE_URL}${editfile}`} className='img-fluid rounded-3' alt="" />
                                        </div>
                                    </div>}


                                    <div className="col-md-12">
                                        <button className='btn bg-blue text-white'>Submit</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </section>
                </>
            } />
        </>
    )
}

export default AddProduct
