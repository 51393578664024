import React, { useEffect, useState } from 'react'
import Sidebaar from '../../Component/Sidebar'

import { getWithheader, postwithheader } from '../../Api/Api'
import { toast } from 'react-toastify'
import Loader from '../../Component/Loader'
import Table from '../../Component/Table'

const ApplyCoupon = () => {
    const [loading, setloding] = useState(false)
    let token = localStorage.getItem("paneltoken")
    const [data, setdata] = useState("")
    let userid = localStorage.getItem("userid")
    const [code, setcode] = useState("")
    const [couponstaus, setcouponstaus] = useState("used")
    const handlesubmit = async (e) => {
        setloding(true)
        e.preventDefault()
        let requestdata = {
            code,
            vendorId: userid,
        }
        let res = await postwithheader('apply-offer', requestdata, token)

        if (res.error == 0) {
            setloding(false)
            toast.success(res.message)
        } else {
            toast.error(res.message)
            setloding(false)
        }
    }


    const handleget = async () => {
        const res = await getWithheader(`generate-code?status=${couponstaus}&user=${userid}`);
        console.log(res.data)
        setdata(res.data)
    }

    const getRowId = (row) => row._id

    useEffect(() => {
        handleget()
    }, [couponstaus])

    const columns = [

        {
            field: "code",
            headerName: "Code",
            flex: 1,

        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
        },




    ]


    return (
        <>
            {loading && <Loader />}
            <Sidebaar
                content={
                    <>
                        <section>
                            <div className="container">
                                <form action="" onSubmit={handlesubmit}>
                                    <div className="row gy-3">
                                        <div className="col-md-4">
                                            <label htmlFor="name">Coupon Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={code}
                                                onChange={(e) => setcode(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn bgblue text-white px-5" >
                                                Apply
                                            </button>
                                        </div>

                                        {/* <div className="col-md-12">
                                    <div className="col-md-12">
                                        <Table  columns={columns}  />
                                    </div>
                                </div> */}
                                    </div>
                                </form>
                                <div className="row justify-content-end">
                                    <div className="col-md-4">
                                        <label htmlFor="couponStatus">Coupon Status</label>
                                        <select name=""
                                            value={couponstaus}
                                            onChange={(e) => setcouponstaus(e.target.value)}
                                            className='form-control form-select'
                                            id="">
                                            <option value="">Select Coupon Status</option>
                                            <option value="used">Used</option>
                                            <option value="expired">Expired</option>
                                            <option value="active">Active</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Table data={data} columns={columns} getRowId={getRowId} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </>
                }
            />
        </>
    )
}

export default ApplyCoupon